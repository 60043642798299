<template>
  <v-container fluid>
     <TitleBar :title="'รายงาน'" :subtitle="'0 รายงาน'">
      <template slot="action">
         <!--  <v-btn x-large color="primary"><v-icon>mdi-plus</v-icon><span class="text-title-2">สร้างแบบฟอร์ม</span></v-btn> -->
      </template>
    </TitleBar>
    <FilterBar>
      <template slot="left">
         <v-col cols="12" md="4" >
                <Select :title="'ประเภท'" :placeholder="'โปรดเลือก'"/>
              </v-col>
      </template>
      <template slot="right">
        <v-col cols="12" md="3" >
                 <Select :title="'รายงาน'" :placeholder="'โปรดเลือก'"/>
              </v-col>
               <v-col cols="12" md="2" >
                <DatePicker :title="'เริ่มต้น'" :placeholder="'โปรดเลือก'" v-model="startDate"/>
              </v-col>
               <v-col cols="12" md="2" >
                <DatePicker :title="'สิ้นสุด'" :placeholder="'โปรดเลือก'" v-model="endDate"/>
              </v-col>
                <v-col cols="12" md="1" align-self="center" >
                <v-btn class="mt-n3" x-large color="primary">เรียกดู</v-btn>
              </v-col>
      </template>
    </FilterBar>
    <v-row class="pl-5 pr-10">
      <v-col cols="12">
        <BarChartCard/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
// import TextField from '@/components/common/TextField'
import Select from '@/components/common/Select'
import FilterBar from '@/components/common/FilterBar'
import DatePicker from '@/components/common/DatePicker'
import BarChartCard from '@/components/formreport/BarChartCard'
// import DataTable from '@/components/common/DataTable'

export default {
  components: {
    TitleBar,
    // TextField,
    DatePicker,
    Select,
    FilterBar,
    BarChartCard
    // DataTable
  },
  data () {
    return {
      headers: [
        { text: 'ชื่อ', value: 'name', class: 'primary lighten-5' },
        { text: 'ประเภท', value: 'type', class: 'primary lighten-5' },
        { text: 'ประจำปี', value: 'year', class: 'primary lighten-5' },
        { text: 'วันที่สร้าง', value: 'createdate', class: 'primary lighten-5' },
        { text: 'ผู้สร้าง', value: 'createby', class: 'primary lighten-5' },
        { text: 'จำนวนคนตอบ', value: 'total', class: 'primary lighten-5' },
        { text: 'สถานะ', value: 'status', class: 'primary lighten-5' },
        { text: 'Action', value: 'action', class: 'primary lighten-5' }
      ],
      loading: true,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10)
    }
  }

}
</script>

<style>

</style>
